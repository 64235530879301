.imb-input {
  input:focus-within ~ label,
  textarea:focus-within ~ label,
  input:not(:placeholder-shown) ~ label,
  textarea:not(:placeholder-shown) ~ label {
    @apply transform -translate-y-3 text-2xs;
  }

  input:focus-within ~ label,
  textarea:focus-within ~ label {
    @apply mt-1 text-blue-5;
  }
}

.button-native {
  border-radius: 50px !important;
}

textarea {
  resize: none !important;
}

.card-icon-bg {
  @apply relative;
}
.card-icon-bg:before {
  content: '';
  @apply absolute top-0 left-0 w-full h-full bg-white rounded opacity-30;
}
/*
.item-radio-checked {
    @apply border-solid shadow-2xl border-no border-gray-30;
} */

.important-text-gray-20 {
  @apply text-gray-20 !important;
}
body.dark .important-text-gray-20 {
  @apply text-gray-200 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  border: 0;
  -webkit-text-fill-color: #222428;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

body.dark input:-webkit-autofill,
body.dark input:-webkit-autofill:hover,
body.dark input:-webkit-autofill:focus,
body.dark input:-webkit-autofill:active {
  border: 0;
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #2d3748 inset;
  transition: background-color 5000s ease-in-out 0s;
}

/** Pop calendar */
.pop-calendar .popover-arrow, .pop-calendar::part(arrow) {
  display: none;
}
.pop-calendar .popover-content, .pop-calendar::part(content) {
  left: 5% !important;
  width: 90%;
}
.react-calendar__navigation {
  text-align: center;
  margin-bottom: 5px;
}
.react-calendar__navigation__arrow {
  width: 30px;
}
.react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation__next-button {
    width: 30px;
    position: absolute;
    right: 10px;
    font-size: 30px;
    color: #A5A7AF;
    top: 0;
}
.react-calendar__navigation__prev-button {
  width: 30px;
  position: absolute;
  left: 10px;
  font-size: 30px;
  color: #A5A7AF;
  top: 0;
}
.react-calendar__navigation__label__labelText {
  font-weight: bold;
}
.react-calendar__month-view__weekdays__weekday {
  font-weight: 500;
  font-size: 12px;
  color: #718096;
  text-align: center;
  padding: 10px 0 10px 0;
}
.pop-calendar abbr[title] {
  text-decoration: none;
}
.react-calendar {
  height: 300px;
}
.react-calendar__tile {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 0 10px 0;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #A0AEC0;
}
.react-calendar__tile abbr {
  padding: 5px;
  border-radius: 5px;
  background: #EDF2F7;
}
.react-calendar__tile:disabled abbr,
.react-calendar__tile[disabled] abbr{
  background: #ffffff;
}
.react-calendar__tile.react-calendar__tile--now abbr {
  color: #ffffff;
  background: #00A7E1;
}
/*:host(#ion-react-wrapper) {
  left: 5%!important;
  width: 90% !important;
}*/
/*#ion-react-wrapper {
  left: 5%!important;
  width: 90% !important;
}
.popover-custom #ion-react-wrapper {
  left: auto !important;
  margin-right: 5%!important;
  width: 100% !important;
  @apply bg-gray-800;
}*/

/** Main slider */
.swiper-pagination.swiper-pagination-bullets {
  position: fixed;
  bottom: 80px;
}
.swiper-pagination-bullet {
  background: #00A7E1;
}
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 6px;
}

/** Demo hide */
body.demo .action-sheet-group {
  display: none !important;
}
body.demo .action-sheet-group.action-sheet-group-cancel {
  display: block !important;
}
body.demo .demo-hide-cont {
  display: none !important;
}
.show-demo-bar {
  display: none !important;
}
body.demo .show-demo-bar {
  display: flex !important;
}
.popover-arrow.sc-ion-popover-ios::after {
  background: #2D3748;
}
.hideit {
  display: none !important;
}

.hide-dark {
  display: block;
}
.show-dark {
  display: none;
}
body.dark .hide-dark {
  display: none;
}
body.dark .show-dark {
  display: block;
}

.ion-page {
  @apply max-w-3xl mx-auto;
}
.calc-height-25 {
  height: calc(100% - 250px);
}
.calc-height-10 {
  height: calc(100% - 100px);
}

.h-full-minus {
  height: calc(100% - 50px);
}

.recharts-default-legend {
  margin-left: 15px !important;
}

.min-h-20 {
  min-height: 20px;
}


.white-placeholder::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  @apply text-white;
  opacity: 1; /* Firefox */
}

.white-placeholder:-ms-input-placeholder { /* Internet Explorer 10-11 */
  @apply text-white;
}

.white-placeholder::-ms-input-placeholder { /* Microsoft Edge */
  @apply text-white;
}

.tooltip-popup {
margin-top: -14px;
}
.single-notification p {
  @apply mb-2;
}

/*color-scheme: light dark;*/
