/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #00A7E1;
  --ion-color-primary-rgb: 0, 127, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #4ac3ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #e74459;
  --ion-color-danger-rgb: 231, 68, 89;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e74459;
  --ion-color-danger-tint: #e74459;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #7e7e83;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #7e7e83;
  --ion-color-medium-tint: #7e7e83;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** gray **/
  --ion-color-gray: #dcdde0;
  --ion-color-gray-rgb: 220, 221, 224;
  --ion-color-gray-contrast: #ffffff;
  --ion-color-gray-contrast-rgb: 255, 255, 255;
  --ion-color-gray-shade: #dcdde0;
  --ion-color-gray-tint: #dcdde0;

  /** gray background **/
  --ion-color-graybg: #edf2f7;
  --ion-color-graybg-rgb: 237, 242, 247;
  --ion-color-graybg-contrast: #ffffff;
  --ion-color-graybg-contrast-rgb: 255, 255, 255;
  --ion-color-graybg-shade: #edf2f7;
  --ion-color-graybg-tint: #edf2f7;

    /** gray background button **/
    --ion-color-graybgbut: #edf2f7;
    --ion-color-graybgbut-rgb: 237, 242, 247;
    --ion-color-graybgbut-contrast: #ffffff;
    --ion-color-graybgbut-contrast-rgb: 255, 255, 255;
    --ion-color-graybgbut-shade: #edf2f7;
    --ion-color-graybgbut-tint: #edf2f7;

  /** gray dark background **/
  --ion-color-graydarkbg: #2d3748;
  --ion-color-graydarkbg-rgb: 45, 55, 72;
  --ion-color-graydarkbg-contrast: #ffffff;
  --ion-color-graydarkbg-contrast-rgb: 255, 255, 255;
  --ion-color-graydarkbg-shade: #2d3748;
  --ion-color-graydarkbg-tint: #2d3748;

    /** gray disabled color **/
    --ion-color-graydisabled: #CBD5E0;
    --ion-color-graydisabled-rgb: 203, 213, 224;
    --ion-color-graydisabled-contrast: #ffffff;
    --ion-color-graydisabled-contrast-rgb: 255, 255, 255;
    --ion-color-graydisabled-shade: #CBD5E0;
    --ion-color-graydisabled-tint: #CBD5E0;

  /** white background **/
  --ion-color-whitebg: #ffffff;
  --ion-color-whitebg-rgb: 255, 255, 255;
  --ion-color-whitebg-contrast: #ffffff;
  --ion-color-whitebg-contrast-rgb: 255, 255, 255;
  --ion-color-whitebg-shade: #ffffff;
  --ion-color-whitebg-tint: #ffffff;

  /** orange **/
  --ion-color-orange: #f18c20;
  --ion-color-orange-rgb: 0, 127, 255;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 255, 255, 255;
  --ion-color-orange-shade: #f8bd7d;
  --ion-color-orange-tint: #f8bd7d;

  /** blue **/
  --ion-color-blue: #00A7E1;
  --ion-color-blue-rgb: 0, 167, 255;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #00A7E1;
  --ion-color-blue-tint: #00A7E1;
}

/*@media (prefers-color-scheme: dark) {*/
/*
   * Dark Colors
   * -------------------------------------------
   */

body.dark {
 /* --ion-color-primary: #f18c20;
  --ion-color-primary-rgb: 0, 127, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #f18c20;
  --ion-color-primary-tint: #f18c20;*/

  --ion-color-primary: #00A7E1;
  --ion-color-primary-rgb: 0, 127, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;

  /** gray background **/
  --ion-color-graybg: #1a202c;
  --ion-color-graybg-rgb: 26, 32, 44;
  --ion-color-graybg-contrast: #000000;
  --ion-color-graybg-contrast-rgb: 0, 0, 0;
  --ion-color-graybg-shade: #1a202c;
  --ion-color-graybg-tint: #1a202c;

    /** gray background button**/
    --ion-color-graybgbut: #272e3c;
    --ion-color-graybgbut-rgb: 26, 32, 44;
    --ion-color-graybgbut-contrast: #000000;
    --ion-color-graybgbut-contrast-rgb: 0, 0, 0;
    --ion-color-graybgbut-shade: #272e3c;
    --ion-color-graybgbut-tint: #272e3c;

  /** gray dark background **/
  --ion-color-graydarkbg: #2d3748;
  --ion-color-graydarkbg-rgb: 45, 55, 72;
  --ion-color-graydarkbg-contrast: #ffffff;
  --ion-color-graydarkbg-contrast-rgb: 255, 255, 255;
  --ion-color-graydarkbg-shade: #2d3748;
  --ion-color-graydarkbg-tint: #2d3748;

      /** gray disabled color **/
      --ion-color-graydisabled: #CBD5E0;
      --ion-color-graydisabled-rgb: 203, 213, 224;
      --ion-color-graydisabled-contrast: #ffffff;
      --ion-color-graydisabled-contrast-rgb: 255, 255, 255;
      --ion-color-graydisabled-shade: #CBD5E0;
      --ion-color-graydisabled-tint: #CBD5E0;

}


/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body.dark {
  --ion-background-color: #1a202c;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #fff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #1a202c;
  --ion-color-step-100: #2d3748;
  --ion-color-step-150: #2d3748;
  --ion-color-step-200: #2d3748;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #1a202c;

  --ion-card-background: #2d3748;
}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1a202c;
  --ion-color-step-100: #2d3748;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}
/*}*/

.ion-color-gray {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}
.ion-color-graybg {
  --ion-color-base: var(--ion-color-graybg);
  --ion-color-base-rgb: var(--ion-color-graybg-rgb);
  --ion-color-contrast: var(--ion-color-graybg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-graybg-contrast-rgb);
  --ion-color-shade: var(--ion-color-graybg-shade);
  --ion-color-tint: var(--ion-color-graybg-tint);
}
.ion-color-graybgbut {
  --ion-color-base: var(--ion-color-graybgbut);
  --ion-color-base-rgb: var(--ion-color-graybgbut-rgb);
  --ion-color-contrast: var(--ion-color-graybgbut-contrast);
  --ion-color-contrast-rgb: var(--ion-color-graybgbut-contrast-rgb);
  --ion-color-shade: var(--ion-color-graybgbut-shade);
  --ion-color-tint: var(--ion-color-graybgbut-tint);
}
.ion-color-graydarkbg {
  --ion-color-base: var(--ion-color-graydarkbg);
  --ion-color-base-rgb: var(--ion-color-graydarkbg-rgb);
  --ion-color-contrast: var(--ion-color-graydarkbg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-graydarkbg-contrast-rgb);
  --ion-color-shade: var(--ion-color-graydarkbg-shade);
  --ion-color-tint: var(--ion-color-graydarkbg-tint);
}
.ion-color-graydisabled {
  --ion-color-base: var(--ion-color-graydisabled);
  --ion-color-base-rgb: var(--ion-color-graydisabled-rgb);
  --ion-color-contrast: var(--ion-color-graydisabled-contrast);
  --ion-color-contrast-rgb: var(--ion-color-graydisabled-contrast-rgb);
  --ion-color-shade: var(--ion-color-graydisabled-shade);
  --ion-color-tint: var(--ion-color-graydisabled-tint);
}
.ion-color-whitebg {
  --ion-color-base: var(--ion-color-whitebg);
  --ion-color-base-rgb: var(--ion-color-whitebg-rgb);
  --ion-color-contrast: var(--ion-color-whitebg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-whitebg-contrast-rgb);
  --ion-color-shade: var(--ion-color-whitebg-shade);
  --ion-color-tint: var(--ion-color-whitebg-tint);
}
.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}
.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}
